* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

@font-face {
  font-family: Avenir300;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Light.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Light.woff') format('woff');
}

@font-face {
  font-family: Avenir400;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff') format('woff');
}

@font-face {
  font-family: Avenir500;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Medium.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Medium.woff') format('woff');
}

@font-face {
  font-family: Avenir600;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.woff') format('woff');
}

@font-face {
  font-family: Avenir700;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff') format('woff');
}

@font-face {
  font-family: Avenir800;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Heavy.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/AvenirNextCyr-Heavy.woff') format('woff');
}
